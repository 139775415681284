import type {
  PresetApi,
  ContentManager,
  IPageDescription,
  ISectionBrief,
  SitePart,
  ESocialPlatform,
  ElementSubType,
} from '@/presetApi';

import { CeType } from '@/presetApi';

export type UserContactInfo = { [key in ElementSubType]?: string };

export interface SocialLink {
  id: string;
  url: string;
  Title: string;
  relativeUri: string;
  platform: ESocialPlatform;
}

export interface PresetContentMediaItem {
  width: number;
  height: number;
  uri: string;
  Title: string;
  fileUrl: string;
  mediaType: string;
  relativeUri: string;
}

export interface PaasSocialLinkData {
  fileName: string;
  relativeUri: string;
  width: number;
  height: number;
  Link: {
    targetUrl: string;
  };
  mediaType: string;
  platform: string;
  Title: string;
}

export interface HeaderFooterContentItemData {
  title: string;
  subTitle: string;
  email: string;
  menu: boolean;
  login: boolean;
  copyrightMSG: string;
  media: PresetContentMediaItem[];
  'social.media': PaasSocialLinkData[];
}

const overrideContentItemsIfNeed = (
  contentItems: any,
  presetAPI: any,
  userContactInfo?: UserContactInfo,
): any => {
  if (contentItems.ceType === 'Contact' && userContactInfo) {
    return presetAPI.overrideContentItemValues(contentItems, userContactInfo);
  }
  return contentItems;
};

export const maybeGetContentItems = (
  caas: ContentManager,
  ceType: CeType | any,
  isList: boolean,
) => {
  const MAX_OFFSET = 99;
  const randomOffset = Math.floor(Math.random() * MAX_OFFSET);

  return isList
    ? caas.generateListContentItems(ceType, 3, 3, randomOffset)
    : caas.generateContentItems(ceType, 3, randomOffset);
};

export function getContentItemList(
  caas: ContentManager,
  presetAPI: PresetApi,
  caasPage: IPageDescription,
  userContactInfo?: UserContactInfo,
  headerFooterContentItemData?: HeaderFooterContentItemData,
): any {
  const contentItemList = caasPage.sections.reduce(function (
    currContentItemList: any,
    section: ISectionBrief,
  ) {
    const maybeContentItems = maybeGetContentItems(
      caas,
      section,
      section.cardinality > 1,
    );
    if (maybeContentItems) {
      let contentItems = {
        contentItems: maybeContentItems,
        isList: section.cardinality > 1,
        ceType: section.ceType,
      };
      contentItems = overrideContentItemsIfNeed(
        contentItems,
        presetAPI,
        userContactInfo,
      );
      currContentItemList.push(contentItems);
    }
    return currContentItemList;
  }, []);
  if (headerFooterContentItemData) {
    const headerItem = {
      ceType: CeType.GeneralDescription,
      sitePart: 'header',
      isList: false,
      contentItems: [headerFooterContentItemData],
    };
    const footerItem = {
      ceType: CeType.GeneralDescription,
      sitePart: 'footer',
      isList: false,
      contentItems: [headerFooterContentItemData],
    };
    contentItemList.unshift(headerItem);
    contentItemList.push(footerItem);
  }
  return contentItemList;
}

export async function getContentItems(
  caas: ContentManager,
  ceType: ISectionBrief | CeType,
  isList: boolean,
  sitePart: SitePart,
): Promise<any> {
  return {
    isList,
    ceType,
    sitePart,
    contentItems: maybeGetContentItems(caas, ceType, isList),
  };
}
