import { EditorPaasApi } from './editorPaasApi';
import { EditorAPIKey, EditorPaasApiKey } from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';

export class EditorPaasScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const EditorPaasApiEntryPoint = createEntryPoint({
  name: 'EditorPaasApi',
  Scope: EditorPaasScope,
  publicApi({ contributeApi }) {
    contributeApi(EditorPaasApiKey, EditorPaasApi);
  },
  initialize() {},
});
