import _ from 'lodash';
import constants from '@/constants';
import { BasePublicApi } from '@/apilib';
import { adapter } from '@/presetApi';
import {
  adjustMobileHeaderBusinessName,
  runPageMobileAdjustments,
} from './editorPaasMobileAdjustments';
import {
  url,
  sections,
  isDebugMode,
  fedopsLogger,
  siteCreation,
  object as objectUtils,
} from '@/util';
import {
  getContentItems,
  type UserContactInfo,
  getContentItemList,
  type HeaderFooterContentItemData,
} from './caasUtils';
import {
  getSiteNameComp,
  convertToSections,
  reverseStripsOrder,
  getFontThemeToUpdate,
  paasColorsToInverseEntries,
  inverseSiteThemeByPaasColorsEntries,
  reversePaasFontsToInvert,
  paasFontsToInvert,
} from './paasUtils';

import type {
  CeType,
  ContentItems,
  PreferredMedia,
  PaaSPageRenderedModel,
  HeaderFooterContentItem,
  PageAlternative,
  PaaSRenderedModel,
  ISectionBrief,
  ContentManager,
  IPageDescription,
  PresetApi,
} from '@/presetApi';
import type { EditorPaasScope } from './editorPaasApiEntry';
import type { PageMobileAdjustmentsConfig } from './editorPaasMobileAdjustments';
import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  CompStructure,
  DocumentServicesObject,
} from 'types/documentServices';

import experiment from 'experiment';

export interface PresetSolution {
  children: PresetSolution[];
  compDef: CompStructure;
  compRef: CompRef;
  from: {
    type?: string;
    subType?: string;
  };
}

export interface PaasSolution {
  desktop: PresetSolution;
  mobile: PresetSolution;
}

let shouldInversePaletteUponAdd = false;
let shouldPreserveEditorFontsOrder = false;

const isDev = isDebugMode() || url.isQA();

function getPaasWrappedDocumentServices(
  documentServices: DocumentServicesObject,
): DocumentServicesObject {
  function wrapWithInversionOverride(
    fn: Function,
    paramToOverrideIndex: number,
  ) {
    return (...args: any[]) => {
      if (shouldInversePaletteUponAdd) {
        args[paramToOverrideIndex] = objectUtils.invertObjectByEntries(
          args[paramToOverrideIndex],
          paasColorsToInverseEntries,
        );
      }
      return fn.apply(null, args);
    };
  }
  return _.defaultsDeep(
    {
      theme: {
        styles: {
          update: wrapWithInversionOverride(
            documentServices.theme.styles.update,
            1,
          ),
        },
        fonts: {
          getAll: () => {
            const fontTheme = documentServices.theme.fonts.getAll();
            if (shouldPreserveEditorFontsOrder) {
              const fontThemeToUpdate = getFontThemeToUpdate(documentServices);
              const inversedFontThemeToUpdate =
                objectUtils.invertObjectByKeyToValueEntries(
                  fontThemeToUpdate,
                  reversePaasFontsToInvert,
                );
              return Object.assign(fontTheme, inversedFontThemeToUpdate);
            }
            return fontTheme;
          },
        },
      },
      components: {
        style: {
          update: wrapWithInversionOverride(
            documentServices.components.style.update,
            1,
          ),
        },
        add: (parentRef: CompRef, compDef: CompStructure, ...rest: any[]) => {
          if (compDef?.componentType === constants.COMP_TYPES.PHOTO) {
            if (experiment.isOpen('se_defaultImageAutoFill')) {
              Object.assign(compDef.props, { autoFill: true });
            }
          }
          if (compDef?.componentType === constants.COMP_TYPES.TEXT) {
            if (shouldPreserveEditorFontsOrder) {
              compDef = objectUtils.invertObjectByKeyToValueEntries(
                compDef,
                paasFontsToInvert,
              );
            }
          }
          return wrapWithInversionOverride(documentServices.components.add, 1)(
            parentRef,
            compDef,
            ...rest,
          );
        },
      },
    },
    documentServices,
  );
}

let presetAPI: PresetApi = null;
let presetApiPromise: Promise<void> = null;

async function init({ editorAPI }: EditorPaasScope) {
  if (presetAPI) {
    throw new Error('init editorPaas');
  }
  const startTime = performance.now();
  if (isDev) console.log('adi-preset-api init start');
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADI_PAAS.ADI_PAAS_INIT,
  );
  const { PresetApi } = await adapter();
  presetAPI = new PresetApi();
  await presetAPI.setdocumentServicesHandle(
    getPaasWrappedDocumentServices(editorAPI.documentServices),
  );
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.ADI_PAAS.ADI_PAAS_INIT,
  );
  const endTime = performance.now();
  if (isDev)
    console.log(
      `adi-preset-api init took: ${Math.round(endTime - startTime)}ms`,
    );
}

async function getPresetApiInitPromise() {
  return presetApiPromise;
}

async function initializePresetApiIfNeeded(scope: EditorPaasScope) {
  if (!presetApiPromise) {
    presetApiPromise = init(scope);
  }
  await presetApiPromise;
}

export async function getSectionPresets(
  scope: EditorPaasScope,
  caas: ContentManager | null,
  topicDisplayName: ISectionBrief | CeType,
  isReplacing: boolean,
  contentItems?: ContentItems | HeaderFooterContentItem,
  preferredMedia?: PreferredMedia,
  maxPresets: number = 10000,
): Promise<any> {
  setPreserveEditorFontsOrderIfNeeded(scope.editorAPI);
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADI_PAAS.GET_SECTION_PRSEET,
  );
  await initializePresetApiIfNeeded(scope);
  contentItems =
    contentItems ||
    (await getContentItems(caas, topicDisplayName, false, 'body'));
  const sectionPresets = await presetAPI.getSectionPresets(
    contentItems,
    maxPresets,
    true,
    isReplacing,
    preferredMedia,
  );
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.ADI_PAAS.GET_SECTION_PRSEET,
  );
  return sectionPresets;
}

export async function getPagePresets(
  scope: EditorPaasScope,
  caas: ContentManager,
  page: IPageDescription,
  userContactInfo: UserContactInfo,
  countOrCuratedTemplateIds: number | number[] = 3,
  headerFooterContentItemData?: HeaderFooterContentItemData,
): Promise<PageAlternative[]> {
  await initializePresetApiIfNeeded(scope);
  const contentItemList = getContentItemList(
    caas,
    presetAPI,
    page,
    userContactInfo,
    headerFooterContentItemData,
  );

  return presetAPI.getPagePresets(
    contentItemList,
    countOrCuratedTemplateIds,
    true,
  );
}

function setPreserveEditorFontsOrderIfNeeded(editorAPI: EditorAPI): void {
  if (!shouldPreserveEditorFontsOrder) {
    const masterPageRef = editorAPI.components.get.byId(
      editorAPI.pages.getMasterPageId(),
    );
    const preserveEditorFontsOrder = editorAPI.components.features.get(
      masterPageRef,
      sections.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    )?.preserveEditorFontsOrder;
    if (
      preserveEditorFontsOrder &&
      !siteCreation.isSiteCreationSectionsTemplate()
    ) {
      shouldPreserveEditorFontsOrder = true;
    }
  }
}

export async function addSectionPreset(
  scope: EditorPaasScope,
  presetPreview: any,
  targetContainer: CompRef,
  top: number,
  shouldInversePalette: boolean = false,
): Promise<CompRef> {
  if (shouldInversePalette) {
    shouldInversePaletteUponAdd = true;
  }
  const paasSolution = (await presetAPI.addPreset(presetPreview, {
    page: targetContainer,
    top,
  })) as PaaSRenderedModel;
  shouldInversePaletteUponAdd = false;
  const sectionRef = paasSolution.desktop.children[0].compRef as CompRef;

  scope.editorAPI.components.hooks.componentAddedToStage.fire({
    compRef: sectionRef,
    origin: 'sectionLayout',
    type: 'click',
  });

  return sectionRef;
}

const updateDmIsSectionsEnabled = (editorAPI: EditorAPI) => {
  const masterPageRef = editorAPI.components.get.byId(
    editorAPI.pages.getMasterPageId(),
  );
  editorAPI.components.features.update(
    masterPageRef,
    sections.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    {
      isSectionsEnabled: true,
    },
  );
};

export async function addPagePreset(
  scope: EditorPaasScope,
  pageAlternative: PageAlternative,
  isHomepage: boolean,
  pageName: string = 'welcome',
  shouldNavigateToPage: boolean = true,
  sectionsCeTypes: CeType[],
  shouldInversePalette: boolean = false,
): Promise<PaaSPageRenderedModel> {
  if (shouldInversePalette) {
    shouldInversePaletteUponAdd = true;
  }
  const { editorAPI } = scope;
  const pageModel = await presetAPI.addPagePreset(
    pageAlternative,
    pageName,
    shouldNavigateToPage,
  );
  await editorAPI.waitForChangesAppliedAsync();

  if (isDebugMode()) console.log(pageModel);

  await reverseStripsOrder(editorAPI, pageModel);

  if (sections.isSectionsEnabled()) {
    if (isHomepage) {
      pageAlternative.presetPreviews.shift();
      pageAlternative.presetPreviews.pop();
      updateDmIsSectionsEnabled(editorAPI);
    }
    await convertToSections(editorAPI, pageModel, sectionsCeTypes);
  }
  await editorAPI.waitForChangesAppliedAsync();
  shouldInversePaletteUponAdd = false;
  return pageModel;
}

async function runPaasPageMobileAdjustments(
  { editorAPI }: EditorPaasScope,
  pageMobileAdjustmentsConfig: PageMobileAdjustmentsConfig,
): Promise<void> {
  await runPageMobileAdjustments(editorAPI, pageMobileAdjustmentsConfig);
}

export function onPresetPreviewBeforeRender(presetPreview: any): void {
  presetAPI.onPresetPreviewBeforeRender(presetPreview);
}

function inverseSitePaletteByPaasEntries({ editorAPI }: EditorPaasScope): void {
  inverseSiteThemeByPaasColorsEntries(editorAPI);
}

async function inverseSiteFontThemeByPaasEntries({
  editorAPI,
}: EditorPaasScope): Promise<void> {
  const fontThemeToUpdate = getFontThemeToUpdate(editorAPI.documentServices);
  const inversedFontTheme = objectUtils.invertObjectByKeyToValueEntries(
    fontThemeToUpdate,
    paasFontsToInvert,
  );
  // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
  editorAPI.theme.fonts.update(inversedFontTheme);
  await editorAPI.waitForChangesAppliedAsync();
  shouldPreserveEditorFontsOrder = true;
  const masterPageRef = editorAPI.components.get.byId(
    editorAPI.pages.getMasterPageId(),
  );
  editorAPI.components.features.update(
    masterPageRef,
    sections.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    {
      preserveEditorFontsOrder: true,
    },
  );
}

function getPaasSiteNameComp({ editorAPI }: EditorPaasScope): CompRef {
  return getSiteNameComp(editorAPI);
}

export class EditorPaasApi extends BasePublicApi<EditorPaasScope> {
  init = this.bindScope(initializePresetApiIfNeeded);
  getPagePresets = this.bindScope(getPagePresets);
  addPagePreset = this.bindScope(addPagePreset);
  getPresetApiInitPromise = getPresetApiInitPromise;
  addSectionPreset = this.bindScope(addSectionPreset);
  getSectionPresets = this.bindScope(getSectionPresets);
  onPresetPreviewBeforeRender = onPresetPreviewBeforeRender;
  getPaasSiteNameComp = this.bindScope(getPaasSiteNameComp);
  runPaasPageMobileAdjustments = this.bindScope(runPaasPageMobileAdjustments);
  inverseSitePaletteByPaasEntries = this.bindScope(
    inverseSitePaletteByPaasEntries,
  );
  adjustMobileHeaderBusinessName = this.bindScope(
    adjustMobileHeaderBusinessName,
  );
  inverseSiteFontThemeByPaasEntries = this.bindScope(
    inverseSiteFontThemeByPaasEntries,
  );
}
