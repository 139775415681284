import _ from 'lodash';
import constants from '@/constants';
import {
  isDebugMode,
  object as objectUtils,
  sections as sectionsUtils,
} from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { events } from '@/coreBi';
import {
  getIsSlideshow,
  getSiteNameComp,
  paasColorsToInverseEntries,
  getFormContainer,
} from './paasUtils';

import type { EditorAPI } from '@/editorAPI';
import type { EditorPaasScope } from './editorPaasApiEntry';
import type {
  CompRef,
  CompLayout,
  CompStructure,
} from 'types/documentServices';
import type { PaaSPageRenderedModel, PaaSRenderedModelNode } from '@/presetApi';
type PagePart = 'header' | 'body' | 'footer';
export interface RefAndStructureMap {
  compRef: CompRef;
  compStructure: CompStructure;
}
export interface PageMobileAdjustmentsConfig {
  pageModel: PaaSPageRenderedModel;
  pageName: string;
  shouldRunOnHeaderFooter: boolean;
  shouldInversePalette: boolean;
  curatedTemplateId: number;
}
interface CompIdToParentIdMap {
  [compId: string]: string;
}

const COMPS_NOT_TO_REPARENT = new Set<string>([
  constants.COMP_TYPES.PAGE,
  constants.COMP_TYPES.COLUMN,
  constants.COMP_TYPES.SECTION,
  constants.COMP_TYPES.APP_WIDGET,
  constants.COMP_TYPES.TPA_WIDGET,
  constants.COMP_TYPES.FORM_CONTAINER,
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
  constants.COMP_TYPES.MENU_CONTAINER,
  constants.COMP_TYPES.EXPANDABLE_MENU,
]);
const FORM_COMPS = new Set<string>([
  constants.COMP_TYPES.APP_WIDGET,
  constants.COMP_TYPES.FORM_CONTAINER,
]);
const COMPS_NOT_TO_APPLY_LAYOUT = new Set<string>([
  constants.COMP_TYPES.FORM_CONTAINER,
]);
const COMP_IDS_NOT_TO_COLLECT = new Set([
  'MENU_AS_CONTAINER',
  'MENU_AS_CONTAINER_EXPANDABLE_MENU',
]);
const HEADER = 'header';
const BODY = 'body';
const FOOTER = 'footer';
const FORM_CONTENT_PADDING = 10;
const FORM_MARGIN = 20;
const FORM_CHILD_WIDTH = 270;
const BOTTOM_MARGIN = 30;
const LOGIN_BAR_POSITION = { x: 100, y: 10 };
const CART_POSITION = { x: 215, y: 15 };
const SEARCH_LAYOUT = { x: 20, y: 75, height: 50, width: 280 };
const MENU_AS_CONTAINER_TOGGLE_LAYOUT = { x: 259, y: 10 };
const CART_ICON_WIDTH_WITH_MARGIN = 46;
const UPDATE_BATCH_SIZE = 10;
const MOBILE_PAGE_WIDTH = 320;
let updateCounter = 0;

const isDebugModeOn = isDebugMode();

export const collectCompList = (
  presetSolution: PaaSRenderedModelNode,
): RefAndStructureMap[] => {
  const allSolutionComps: RefAndStructureMap[] = [];
  collectCompListByLevels(presetSolution, allSolutionComps);
  return allSolutionComps;
};

const waitForChangesIfNeeded = async (editorAPI: EditorAPI) => {
  ++updateCounter;
  if (updateCounter % UPDATE_BATCH_SIZE === 0) {
    await editorAPI.waitForChangesAppliedAsync();
  }
};

const getType = (editorAPI: EditorAPI, compRef: CompRef): string => {
  return editorAPI.components.is.exist(compRef)
    ? editorAPI.components.getType(compRef)
    : null;
};

const collectCompListByLevels = (
  presetSolution: PaaSRenderedModelNode,
  refAndLayoutMap: RefAndStructureMap[],
): void => {
  presetSolution.children.forEach((section) => {
    const queue: PaaSRenderedModelNode[] = [];
    const nodes: PaaSRenderedModelNode[] = [];
    queue.push(section);
    while (queue.length) {
      const node = queue.shift();
      nodes.unshift(node);
      node.children.forEach((child) => {
        queue.push(child);
      });
    }
    nodes.forEach(({ compRef, compDef }) => {
      const shouldCollect =
        compRef && compDef && !COMP_IDS_NOT_TO_COLLECT.has(compRef.id);
      if (shouldCollect) {
        refAndLayoutMap.push({
          compRef: compRef as CompRef,
          compStructure: compDef,
        });
      }
    });
  });
};

export const getRenderedToParentMap = (
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
): CompIdToParentIdMap =>
  editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(mobilePartRef, true)
    .reduce((childToParentMap: CompIdToParentIdMap, compRef: CompRef) => {
      if (COMPS_NOT_TO_REPARENT.has(getType(editorAPI, compRef))) {
        return childToParentMap;
      }
      const parentId =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
          compRef,
        ).id;
      return {
        ...childToParentMap,
        [compRef.id]: parentId,
      };
    }, {});

const collectSolutionIdToParentMap = (
  editorAPI: EditorAPI,
  presetSolution: PaaSRenderedModelNode,
  compIdToParentMap: CompIdToParentIdMap,
): void => {
  presetSolution.children.forEach((childSolution) => {
    const compRef = getMobileCompRef(editorAPI, childSolution.compRef?.id);
    if (
      compRef &&
      presetSolution.compRef?.id &&
      !COMPS_NOT_TO_REPARENT.has(childSolution.compDef.componentType)
    ) {
      compIdToParentMap[childSolution.compRef.id] = presetSolution.compRef.id;
    }
    collectSolutionIdToParentMap(editorAPI, childSolution, compIdToParentMap);
  });
};

export const getMobileSolutionToParentMap = (
  editorAPI: EditorAPI,
  mobileSolution: PaaSRenderedModelNode,
): CompIdToParentIdMap => {
  const compIdToParentMap: CompIdToParentIdMap = {};
  collectSolutionIdToParentMap(editorAPI, mobileSolution, compIdToParentMap);
  return compIdToParentMap;
};

const isFormComponent = (editorAPI: EditorAPI, compId: string): boolean => {
  const compRef = getMobileCompRef(editorAPI, compId);
  const compType = getType(editorAPI, compRef);
  const [child] =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef);
  const childType = getType(editorAPI, child);
  const container =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const containerType = getType(editorAPI, container);
  return (
    FORM_COMPS.has(compType) ||
    FORM_COMPS.has(childType) ||
    FORM_COMPS.has(containerType)
  );
};

const getMobileCompRef = (editorAPI: EditorAPI, compId: string): CompRef =>
  editorAPI.components.get.byId(compId, null, 'MOBILE');

export const reparentMobileComps = async (
  editorAPI: EditorAPI,
  mobileSolution: PaaSRenderedModelNode,
): Promise<void> => {
  const mobilePartRef = mobileSolution.compRef as CompRef;
  const renderedToParentMap = getRenderedToParentMap(editorAPI, mobilePartRef);
  const solutionToParentMap = getMobileSolutionToParentMap(
    editorAPI,
    mobileSolution,
  );
  for (const [compId, solutionParentId] of Object.entries(
    solutionToParentMap,
  )) {
    const parentsMismatch = renderedToParentMap[compId] !== solutionParentId;
    const compRef = getMobileCompRef(editorAPI, compId);
    const containerCompRef = getMobileCompRef(editorAPI, solutionParentId);
    if (
      compRef &&
      parentsMismatch &&
      containerCompRef &&
      editorAPI.components.is.containable(compRef, containerCompRef)
    ) {
      editorAPI.dsActions.components.setContainer(compRef, containerCompRef);
      await waitForChangesIfNeeded(editorAPI);
    }
  }
};

const verifyParenting = (
  editorAPI: EditorAPI,
  mobileSolution: PaaSRenderedModelNode,
  mobilePartRef: CompRef,
  curatedTemplateId: number,
): void => {
  const renderedCompToParentMap = getRenderedToParentMap(
    editorAPI,
    mobilePartRef,
  );
  const solutionCompToParentMap = getMobileSolutionToParentMap(
    editorAPI,
    mobileSolution,
  );
  for (const [componentId, solutionParentId] of Object.entries(
    solutionCompToParentMap,
  )) {
    const parentsMismatch =
      renderedCompToParentMap[componentId] !== solutionParentId;
    const compRef = getMobileCompRef(editorAPI, componentId);
    if (parentsMismatch && compRef) {
      const newParent =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
      if (newParent.id !== solutionParentId) {
        const currentParentRef =
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
        const newParentRef = getMobileCompRef(editorAPI, solutionParentId);
        const componentType = getType(editorAPI, compRef);
        const newParentType = getType(editorAPI, newParentRef);
        const currentParentType = getType(editorAPI, currentParentRef);
        editorAPI.bi.event(events.siteCreation.ERROR_PARENTING, {
          componentId,
          componentType,
          currentParentId: currentParentRef.id,
          currentParentType,
          newParentId: newParentRef.id,
          newParentType,
          curatedTemplateId,
        });
        if (isDebugModeOn)
          console.log(
            `FAILED PARENTING: ${compRef.id} COMP: ${componentType} TO: ${solutionParentId} TYPE: ${newParentType} CURRENT PARENT: ${currentParentRef.id} TYPE: ${currentParentType}`,
          );
      }
    }
  }
};

const updateLayoutIfNeeded = async (
  editorAPI: EditorAPI,
  compRef: CompRef,
  newLayout: Partial<CompLayout>,
): Promise<void> => {
  const currentLayout = editorAPI.components.layout.get(compRef);
  if (!currentLayout) return;
  const newLayoutKeys = Object.keys(newLayout);
  const filteredCurrentLayout = _.pick(currentLayout, newLayoutKeys);
  if (_.isEqual(newLayout, filteredCurrentLayout)) return;
  const layoutToUpdate: Partial<CompLayout> = Object.fromEntries(
    Object.entries(newLayout).filter(([, layoutValue]) =>
      _.isNumber(layoutValue),
    ),
  );
  editorAPI.dsActions.components.layout.update(compRef, layoutToUpdate);
  await waitForChangesIfNeeded(editorAPI);
};

export const updatePropertiesIfNeeded = async (
  editorAPI: EditorAPI,
  compRef: CompRef,
  newProperties: any,
) => {
  const propsKeys = Object.keys(newProperties);
  const currentProperties = editorAPI.components.properties.get(compRef);
  if (!currentProperties) return;
  const filteredPreviousProps = _.pick(currentProperties, propsKeys);
  if (_.isEqual(newProperties, filteredPreviousProps)) return;
  editorAPI.dsActions.components.properties.update(compRef, newProperties);
  await waitForChangesIfNeeded(editorAPI);
};

export const applyTextsOverrideColors = async (
  editorAPI: EditorAPI,
  mobileSolution: PaaSRenderedModelNode,
): Promise<void> => {
  if (
    mobileSolution.compRef &&
    mobileSolution.compDef?.componentType === constants.COMP_TYPES.TEXT &&
    !_.isEmpty(mobileSolution.compDef?.props)
  ) {
    await updatePropertiesIfNeeded(
      editorAPI,
      mobileSolution.compRef as CompRef,
      mobileSolution.compDef.props,
    );
  }
  for (const childSolution of mobileSolution.children) {
    await applyTextsOverrideColors(editorAPI, childSolution);
  }
};

const applyLayoutFromSolution = async (
  refAndLayoutMap: RefAndStructureMap[],
  editorAPI: EditorAPI,
) => {
  await editorAPI.waitForChangesAppliedAsync();
  for (const { compStructure, compRef } of refAndLayoutMap) {
    if (COMPS_NOT_TO_APPLY_LAYOUT.has(compStructure.componentType)) continue;
    const { x, y, width, height, scale } = compStructure.layout;
    await updateLayoutIfNeeded(
      editorAPI,
      compRef,
      scale ? { height, width, scale } : { height, width },
    );
    const containerRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const isChildOfSection =
      getType(editorAPI, containerRef) === constants.COMP_TYPES.SECTION;
    if (!isChildOfSection) {
      await updateLayoutIfNeeded(editorAPI, compRef, { x, y });
    }
  }
};

const verifyLayout = (
  editorAPI: EditorAPI,
  refAndLayoutMap: RefAndStructureMap[],
  curatedTemplateId: number,
  mobilePageRef: CompRef,
) => {
  const formRef = getFormContainer(editorAPI, mobilePageRef);
  const ancestors =
    editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(formRef);
  const contactFormFamily = new Set(ancestors.map(({ id }) => id));
  for (const { compStructure, compRef } of refAndLayoutMap) {
    const compExists = getMobileCompRef(editorAPI, compRef.id);
    const shouldNotVerify = COMPS_NOT_TO_APPLY_LAYOUT.has(
      compStructure.componentType,
    );
    if (!compExists || shouldNotVerify) continue;

    const { x, y, width, height } = compStructure.layout;
    const updatedLayout = editorAPI.components.layout.get_rect(compRef);
    const filteredUpdatedLayout = _.pick(updatedLayout, [
      'x',
      'y',
      'width',
      'height',
    ]);
    const compParent =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const parentType = getType(editorAPI, compParent);
    const isParentSection = parentType === constants.COMP_TYPES.SECTION;
    const solutionLayout = { x, y: isParentSection ? 0 : y, width, height }; // TODO we are checking under section, which means the child is at 0, need to modify solution before adjust
    const layoutMatch = _.isEqual(filteredUpdatedLayout, solutionLayout);
    const belongsToForm = contactFormFamily.has(compRef.id);
    const isSlideShow = getIsSlideshow(editorAPI, compRef);
    if (layoutMatch || belongsToForm || isSlideShow) continue;

    const componentType = getType(editorAPI, compRef);
    editorAPI.bi.event(events.siteCreation.ERROR_ADJUSTING_CONTENT, {
      layoutToUpdate: JSON.stringify({ x, y, width, height }),
      resultLayout: JSON.stringify(updatedLayout),
      componentId: compRef.id,
      componentType,
      curatedTemplateId,
    });
    if (isDebugModeOn)
      console.log(
        `FAILED UPDATING LAYOUT ${compRef.id} TRIED: `,
        {
          x,
          y,
          width,
          height,
        },
        `RESULT:`,
        filteredUpdatedLayout,
        `COMP: ${componentType}`,
      );
  }
};

const verifyProperties = (
  editorAPI: EditorAPI,
  refAndLayoutMap: RefAndStructureMap[],
  curatedTemplateId: number,
) => {
  for (const { compStructure, compRef } of refAndLayoutMap) {
    const isText = compStructure?.componentType === constants.COMP_TYPES.TEXT;
    const compExists = getMobileCompRef(editorAPI, compRef.id);
    if (!compExists || !isText || _.isEmpty(compStructure?.props)) continue;

    const newProperties = compStructure.props;
    const updatedProps = editorAPI.components.properties.get(compRef);
    const propsKeys = Object.keys(newProperties).filter(
      (key) => key !== 'metaData',
    );
    const propertiesMatch = _.isEqual(
      _.pick(updatedProps, propsKeys),
      _.pick(newProperties, propsKeys),
    );
    if (propertiesMatch) continue;

    const componentType = getType(editorAPI, compRef);
    editorAPI.bi.event(events.siteCreation.ERROR_UPDATING_PROPERTIES, {
      propertiesToUpdate: JSON.stringify(newProperties),
      resultProperties: JSON.stringify(updatedProps),
      componentId: compRef.id,
      componentType,
      curatedTemplateId,
    });
    if (isDebugModeOn)
      console.log(
        `FAILED UPDATING PROPS: ${compRef.id} TRIED:`,
        newProperties,
        `RESULT:`,
        updatedProps,
        `TYPE: ${componentType}`,
      );
  }
};

const adjustFormSectionHeight = async (
  editorAPI: EditorAPI,
  formContainer: CompRef,
) => {
  const formWidget =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formContainer);
  const formLayout = editorAPI.components.layout.get_rect(formWidget);
  if (!formLayout) return;
  const widgetColumn =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formWidget);
  const widgetStrip =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(widgetColumn);
  const formHeight = formLayout.y + formLayout.height;
  await updateLayoutIfNeeded(editorAPI, widgetStrip, { height: formHeight });
  const ancestors =
    editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(formWidget);
  const [topParent] = ancestors?.slice(-2);
  const [topParentChildStrip] =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(topParent);
  const height = editorAPI.components.get
    .byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
      topParent,
    )
    .reduce((maxHeight, comp) => {
      const stripHeight = editorAPI.components.layout.get_size(comp)?.height;
      return stripHeight > maxHeight ? stripHeight : maxHeight;
    }, formHeight);
  await updateLayoutIfNeeded(editorAPI, topParent, { height });
  await updateLayoutIfNeeded(editorAPI, topParentChildStrip, { height });
};

const adjustFormContainer = async (
  editorAPI: EditorAPI,
  formContainer: CompRef,
) => {
  const formChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(formContainer);
  const totalHeight = formChildren.reduce(
    (acc, childRef) =>
      acc +
      editorAPI.components.layout.get_size(childRef).height +
      FORM_CONTENT_PADDING,
    FORM_CONTENT_PADDING,
  );
  const formWidget =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formContainer);
  const formWidgetHighestSibling = Math.max(
    ...editorAPI.components
      .getChildren_DEPRECATED_BAD_PERFORMANCE(
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
          formWidget,
        ),
      )
      .filter(({ id }) => formWidget.id !== id)
      .map((compRef) => {
        const layout = editorAPI.components.layout.get_rect(compRef);
        return layout.y + layout.height;
      }),
    0,
  );
  const formWidgetY = formWidgetHighestSibling + FORM_MARGIN;
  await updateLayoutIfNeeded(editorAPI, formWidget, { x: 0, y: formWidgetY });
  await updateLayoutIfNeeded(editorAPI, formWidget, {
    height: totalHeight,
  });
  await updateLayoutIfNeeded(editorAPI, formContainer, {
    height: totalHeight,
  });
  await editorAPI.waitForChangesAppliedAsync();
};

const getFormChildrenByOrder = (
  editorAPI: EditorAPI,
  formContainer: CompRef,
) => {
  const formChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(formContainer);
  const formChildrenWithLayout = formChildren.map((compRef: CompRef) => ({
    compRef,
    layout: editorAPI.components.layout.get_rect(compRef),
  }));
  return formChildrenWithLayout
    .sort(({ layout: l1 }, { layout: l2 }) => l1.x - l2.x)
    .sort(({ layout: l1 }, { layout: l2 }) => l1.y - l2.y);
};

const adjustFormChildren = async (
  editorAPI: EditorAPI,
  formContainer: CompRef,
) => {
  try {
    const formChildrenByOrder = getFormChildrenByOrder(
      editorAPI,
      formContainer,
    );
    const formContainerLayout =
      editorAPI.components.layout.get_size(formContainer);
    const childXPositions = (formContainerLayout.width - FORM_CHILD_WIDTH) / 2;
    let totalHeight = FORM_CONTENT_PADDING;
    for (const { compRef, layout } of formChildrenByOrder) {
      await updateLayoutIfNeeded(editorAPI, compRef, {
        x: childXPositions,
        y: totalHeight,
        width: FORM_CHILD_WIDTH,
      });
      totalHeight += layout.height + FORM_CONTENT_PADDING;
    }
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        adjustFormChildren: true,
      },
    });
  } finally {
    await updateLayoutIfNeeded(editorAPI, formContainer, { x: 0, y: 0 });
  }
};

const adjustFormAncestors = async (
  editorAPI: EditorAPI,
  formContainer: CompRef,
): Promise<void> => {
  const formWidget =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formContainer);
  const formWidgetY = editorAPI.components.layout.get_position(formContainer).y;
  const formColumn =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formWidget);
  const totalHeight =
    editorAPI.components.layout.get_size(formContainer).height;
  const formContainerHeight = formWidgetY + totalHeight + FORM_MARGIN;
  const currentFormColumnHeight =
    editorAPI.components.layout.get_size(formColumn).height;
  const offset = Math.abs(formContainerHeight - currentFormColumnHeight);
  const formWidgetAncestors =
    editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(formWidget);
  for (const ancestorRef of formWidgetAncestors) {
    if (getType(editorAPI, ancestorRef) === constants.COMP_TYPES.PAGE) continue;
    const ancestorLayout = editorAPI.components.layout.get_size(ancestorRef);
    if (!ancestorLayout) continue;
    await updateLayoutIfNeeded(editorAPI, ancestorRef, {
      height: ancestorLayout.height + offset,
    });
  }
};

export const adjustFormWidget = async (
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
): Promise<void> => {
  await editorAPI.waitForChangesAppliedAsync();
  const formContainer = getFormContainer(editorAPI, mobilePartRef);
  if (!formContainer) return;
  await adjustFormContainer(editorAPI, formContainer);
  await adjustFormChildren(editorAPI, formContainer);
  await adjustFormAncestors(editorAPI, formContainer);
  await adjustFormSectionHeight(editorAPI, formContainer);
};

const getAllRefsIds = (refAndLayoutMap: RefAndStructureMap[]) =>
  refAndLayoutMap.map(({ compRef }) => compRef.id);

const hideNeededComps = async (
  editorAPI: EditorAPI,
  refAndLayoutMapMobile: RefAndStructureMap[],
  refAndLayoutMapDesktop: RefAndStructureMap[],
) => {
  const idsDesktop = getAllRefsIds(refAndLayoutMapDesktop);
  const idsMobile = getAllRefsIds(refAndLayoutMapMobile);
  const idsToHide = idsDesktop.filter(
    (idDesktop) => !idsMobile.includes(idDesktop),
  );
  for (const idToHide of idsToHide) {
    const compRef = getMobileCompRef(editorAPI, idToHide);
    const containerId =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef)?.id;
    if (
      !compRef ||
      idsToHide.includes(containerId) ||
      isFormComponent(editorAPI, idToHide)
    ) {
      continue;
    }
    editorAPI.mobile.hiddenComponents.hide(compRef);
    await waitForChangesIfNeeded(editorAPI);
  }
};

const verifyHiddenComps = (
  editorAPI: EditorAPI,
  refAndLayoutMapMobile: RefAndStructureMap[],
  refAndLayoutMapDesktop: RefAndStructureMap[],
  mobilePartRef: CompRef,
  curatedTemplateId: number,
) => {
  const idsDesktop = getAllRefsIds(refAndLayoutMapDesktop);
  const idsMobile = getAllRefsIds(refAndLayoutMapMobile);
  const idsToHide = idsDesktop.filter(
    (idDesktop) => !idsMobile.includes(idDesktop),
  );
  const hiddenComponents = editorAPI.mobile.hiddenComponents.get(
    mobilePartRef?.id,
  );
  const notHidden = _.difference(idsToHide, hiddenComponents);
  for (const componentId of notHidden) {
    const compRef = getMobileCompRef(editorAPI, componentId);
    const isForm = isFormComponent(editorAPI, componentId);
    if (!compRef || isForm) continue;
    const componentType = getType(editorAPI, compRef);
    const containerRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const containerType = getType(editorAPI, containerRef);
    editorAPI.bi.event(events.siteCreation.ERROR_HIDING, {
      componentId,
      componentType,
      containerId: containerRef.id,
      containerType,
      curatedTemplateId,
    });
    if (isDebugModeOn)
      console.log(
        `FAILED HIDING: ${componentId} COMP: ${componentType} CONTAINER: ${containerRef.id} TYPE: ${containerType}`,
      );
  }
};

const adjustVerticalSizeAndPosition = async (
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
) => {
  await editorAPI.waitForChangesAppliedAsync();
  let lastYPosition = 0;
  const mainChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(mobilePartRef);
  for (const pageChild of mainChildren) {
    if (sectionsUtils.isSectionsEnabled()) {
      const compType = getType(editorAPI, pageChild);
      const [sectionFirstChildRef] =
        editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(pageChild);
      // TODO this is required for the unit test, need to fix tests for sections
      // first empty section is there, need to remove it in the test
      if (!sectionFirstChildRef || compType !== constants.COMP_TYPES.SECTION)
        continue;
      const { height } =
        editorAPI.components.layout.get_size(sectionFirstChildRef);
      await updateLayoutIfNeeded(editorAPI, sectionFirstChildRef, {
        x: 0,
        y: 0,
      });
      await editorAPI.waitForChangesAppliedAsync();
      await updateLayoutIfNeeded(editorAPI, pageChild, { height });
      await updateLayoutIfNeeded(editorAPI, pageChild, { y: lastYPosition });
      lastYPosition += height;
    } else {
      const { height } = editorAPI.components.layout.get_size(pageChild);
      await updateLayoutIfNeeded(editorAPI, pageChild, { y: lastYPosition });
      lastYPosition += height;
    }
  }
};

const filterMobileHeaderElements = ({ id }: CompRef) => {
  const FILTERED_HEADER_COMP_IDS = ['MENU_AS_CONTAINER'];
  return !FILTERED_HEADER_COMP_IDS.includes(id);
};

const adjustHeaderFooterHeight = async (
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
) => {
  await editorAPI.waitForChangesAppliedAsync();
  const children = editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(mobilePartRef)
    .filter(filterMobileHeaderElements);
  const height = children.reduce((acc, child) => {
    const { y, height } = editorAPI.components.layout.get_rect(child);
    const elementHeight = y + height + BOTTOM_MARGIN;
    if (elementHeight > acc) {
      return elementHeight;
    }
    return acc;
  }, 0);
  await updateLayoutIfNeeded(editorAPI, mobilePartRef, { height });
};

const verifySolutionAdjustment = (
  editorAPI: EditorAPI,
  mobile: PaaSRenderedModelNode,
  curatedTemplateId: number,
  compListMobile: RefAndStructureMap[],
  compListDesktop: RefAndStructureMap[],
) => {
  const mobilePartRef = mobile.compRef as CompRef;
  verifyParenting(editorAPI, mobile, mobilePartRef, curatedTemplateId);
  verifyHiddenComps(
    editorAPI,
    compListMobile,
    compListDesktop,
    mobilePartRef,
    curatedTemplateId,
  );
  verifyLayout(editorAPI, compListMobile, curatedTemplateId, mobilePartRef);
  verifyProperties(editorAPI, compListMobile, curatedTemplateId);
};

const adjustMobileMenuButton = async (
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
) => {
  const menuButton = editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(mobilePartRef)
    .find(({ id }) => id === 'MENU_AS_CONTAINER_TOGGLE');
  if (menuButton) {
    await updateLayoutIfNeeded(
      editorAPI,
      menuButton,
      MENU_AS_CONTAINER_TOGGLE_LAYOUT,
    );
  }
};

async function adjustHeaderWidgets(
  editorAPI: EditorAPI,
  mobilePartRef: CompRef,
) {
  const [loginBar] = editorAPI.components.get.byType(
    constants.COMP_TYPES.LOGIN_SOCIAL_BAR,
    mobilePartRef,
  );
  if (loginBar) {
    editorAPI.components.layout.update(loginBar, LOGIN_BAR_POSITION);
  }
  const [cart] = editorAPI.components.get.byType(
    constants.COMP_TYPES.TPA_WIDGET,
    mobilePartRef,
  );
  if (cart) {
    editorAPI.components.layout.update(cart, CART_POSITION);
  }
  const [searchBar] = editorAPI.components.get.byType(
    constants.COMP_TYPES.SEARCH_BOX,
    mobilePartRef,
  );
  if (searchBar) {
    const searchContainer =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(searchBar);
    editorAPI.components.layout.update(searchContainer, SEARCH_LAYOUT);
  }
}

export const adjustMobileHeaderBusinessName = async ({
  editorAPI,
}: EditorPaasScope): Promise<void> => {
  try {
    const siteNameComp = getSiteNameComp(editorAPI);
    if (!siteNameComp) return;
    const mobileSiteNameComp = editorAPI.components.get.byId(
      siteNameComp.id,
      null,
      'MOBILE',
    );
    const siteNameWidth =
      editorAPI.components.layout.get_size(mobileSiteNameComp)?.width;
    if (!siteNameWidth) return;
    await updateLayoutIfNeeded(editorAPI, mobileSiteNameComp, {
      width: siteNameWidth - CART_ICON_WIDTH_WITH_MARGIN,
    });
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationAdjustBusinessNameMobile: true,
      },
    });
  }
};

const adjustMobileLayout = async (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
  pagePart: PagePart,
  curatedTemplateId: number,
) => {
  const { desktop, mobile } = pageModel[pagePart];
  const mobilePartRef = mobile.compRef as CompRef;
  const compListDesktop = collectCompList(desktop);
  const compListMobile = collectCompList(mobile);
  await reparentMobileComps(editorAPI, mobile);
  await hideNeededComps(editorAPI, compListMobile, compListDesktop);
  await applyLayoutFromSolution(compListMobile, editorAPI);
  await applyLayoutFromSolution(compListMobile, editorAPI);
  await applyTextsOverrideColors(editorAPI, mobile);
  switch (pagePart) {
    case BODY:
      await adjustFormWidget(editorAPI, mobilePartRef);
      await adjustVerticalSizeAndPosition(editorAPI, mobilePartRef);
      break;
    case HEADER:
      await adjustHeaderWidgets(editorAPI, mobilePartRef);
      await adjustMobileMenuButton(editorAPI, mobilePartRef);
      await adjustHeaderFooterHeight(editorAPI, mobilePartRef);
      break;
    case FOOTER:
      await adjustHeaderFooterHeight(editorAPI, mobilePartRef);
      break;
  }
  await editorAPI.waitForChangesAppliedAsync();
  verifySolutionAdjustment(
    editorAPI,
    mobile,
    curatedTemplateId,
    compListMobile,
    compListDesktop,
  );
};

const getPagePartDesktopRef = (
  editorAPI: EditorAPI,
  pagePart: PagePart,
  pageModel: PaaSPageRenderedModel,
): CompRef => {
  if (pagePart === BODY) return pageModel.pageRef as CompRef;
  return editorAPI.components.get.byId(
    pagePart === HEADER ? 'SITE_HEADER' : 'SITE_FOOTER',
    null,
    'DESKTOP',
  );
};

function inverseColorsInDarkPage(
  { componentType, props }: Partial<CompStructure>,
  shouldInversePalette: boolean,
) {
  const isText = componentType === constants.COMP_TYPES.TEXT;
  if (isText && shouldInversePalette && props) {
    const newProperties = objectUtils.invertObjectByEntries(
      props,
      paasColorsToInverseEntries,
    ) as any;
    props.overrideColor = newProperties.overrideColor;
  }
}

const fixCompsWidth = ({ layout, componentType }: Partial<CompStructure>) => {
  const isColumn = componentType === constants.COMP_TYPES.COLUMN;
  const wrongColumnWidth = isColumn && layout?.width === 12;
  const compTooWide = layout?.width > MOBILE_PAGE_WIDTH;
  if (compTooWide || wrongColumnWidth) {
    layout.width = MOBILE_PAGE_WIDTH;
  }
};

/**
 * TODO these fixes should be moved to PAAS,
 * TODO these are only temporary fixes for broken pageModel
 */
const fixPageModel = (
  editorAPI: EditorAPI,
  pagePart: PagePart,
  pageModel: PaaSPageRenderedModel,
  shouldInversePalette: boolean,
): void => {
  const { desktop, mobile } = pageModel[pagePart];
  desktop.compRef = getPagePartDesktopRef(editorAPI, pagePart, pageModel);
  mobile.compRef = getMobileCompRef(editorAPI, desktop.compRef.id);

  const compListMobile = collectCompList(mobile);
  compListMobile.forEach(({ compStructure }: RefAndStructureMap) => {
    fixCompsWidth(compStructure);
    inverseColorsInDarkPage(compStructure, shouldInversePalette);
  });
};

const resetCartToHeader = async (editorAPI: EditorAPI): Promise<void> => {
  const headerMobileCompRef = getMobileCompRef(
    editorAPI,
    editorAPI.siteSegments.getHeader().id,
  );
  const [cart] = editorAPI.components.get.byType(
    constants.COMP_TYPES.TPA_WIDGET,
    headerMobileCompRef,
  );
  if (
    cart &&
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(cart).id !==
      headerMobileCompRef.id
  ) {
    editorAPI.dsActions.components.setContainer(cart, headerMobileCompRef);
    editorAPI.dsActions.components.layout.update(cart, CART_POSITION);
    await editorAPI.waitForChangesAppliedAsync();
  }
};

const headerFooterPreAdjustments = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  try {
    await resetCartToHeader(editorAPI);
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: { siteCreationPreMobileAdjustments: true, siteCreationFlow: true },
    });
  }
};

export const runPageMobileAdjustments = async (
  editorAPI: EditorAPI,
  {
    pageModel,
    pageName,
    shouldRunOnHeaderFooter,
    shouldInversePalette,
    curatedTemplateId,
  }: PageMobileAdjustmentsConfig,
): Promise<void> => {
  try {
    if (isDebugModeOn) console.log(`ADJUSTING PAGE ${pageName}`);
    const pageParts = [BODY] as PagePart[];
    if (shouldRunOnHeaderFooter) {
      await headerFooterPreAdjustments(editorAPI);
      pageParts.push(HEADER, FOOTER);
    }
    for (const pagePart of pageParts) {
      fixPageModel(editorAPI, pagePart, pageModel, shouldInversePalette);
      await adjustMobileLayout(
        editorAPI,
        pageModel,
        pagePart,
        curatedTemplateId,
      );
    }
  } catch (e) {
    console.error('runPageMobileAdjustments error:', e);
    ErrorReporter.captureException(e, {
      tags: { siteCreationPageMobileAdjustments: true, siteCreationFlow: true },
      extra: { pageName, curatedTemplateId },
    });
  }
};
