import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { fedopsLogger, http } from '@/util';

export class Deferred<T = any> {
  public promise: Promise<T>;
  public resolve: (arg?: T) => void = () => {};
  public reject: (arg?: any) => void = () => {};

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export class LayoutElementsFetcher {
  private leZip: any;
  private contactZip: any;
  private readonly preparedQueries = new Set();
  private readonly resourcesUrl: string;
  private readonly contact_query = 'Contact';

  constructor() {
    this.resourcesUrl = `${window.serviceTopology.scriptsLocationMap['site-generator-statics-metadata']}/resources/`;
  }

  fetchFile(zip: any, queryKey: any) {
    if (zip) {
      return this.extractFile(zip, queryKey);
    }
    return http
      .fetch(
        `${this.resourcesUrl}LayoutElements/${encodeURIComponent(
          queryKey,
        )}.raw.js`,
      )
      .then((response: any) => response.data);
  }

  extractFile(zip: any, queryKey: any) {
    const leGroup = zip.file(queryKey);
    if (leGroup) {
      const jsonString = leGroup.asText();
      const layoutElements = JSON.parse(jsonString);
      return Promise.resolve(layoutElements);
    }
    return Promise.reject();
  }

  readZip(name: string) {
    const defer = new Deferred();
    JSZipUtils.getBinaryContent(
      `${this.resourcesUrl + name}.zip.js`,
      function (err: any, data: any) {
        if (err) {
          defer.reject(err);
        } else {
          defer.resolve(new JSZip(data));
        }
      },
    );
    return defer.promise;
  }

  loadLayoutElements = () => {
    // Just read the ZIP without extracting (we will read LE's lazily later)
    return this.readZip('LayoutElements').then((data: any) => {
      this.leZip = data;
    });
  };

  loadContactProtoTypeLayoutElements = () => {
    // read the ZIP
    return this.readZip('ContactProtoTypeLayoutElements').then((data: any) => {
      this.contactZip = data;
      return data;
    });
  };

  init(registerLEs: any) {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADI_PAAS.INIT_LAYOUT_ELEMENTS_FETCHER,
    );
    const self = this;
    //read the ZIP and extract contact proto types
    return self.loadContactProtoTypeLayoutElements().then((data: any) => {
      return this.extractFile(data, '')
        .then(registerLEs)
        .then(self.loadLayoutElements)
        .then((result: any) => {
          fedopsLogger.interactionEnded(
            fedopsLogger.INTERACTIONS.ADI_PAAS.INIT_LAYOUT_ELEMENTS_FETCHER,
          );
          return result;
        });
    });
  }

  prepareContactLEsForFetch(registerLEs: any) {
    return this.prepareForFetch(
      this.contactZip,
      this.contact_query,
      registerLEs,
    );
  }

  prepareForFetch(zip: any, queryKey: any, registerLEs: any) {
    if (this.preparedQueries.has(queryKey)) {
      return Promise.resolve();
    }

    return this.fetchFile(zip, queryKey)
      .then(registerLEs)
      .catch(() => undefined)
      .finally(() => this.preparedQueries.add(queryKey));
  }

  prepareLEsForFetch(width: string, argsSignature: string, registerLEs: any) {
    const queryKey = `${width}_${argsSignature}`;
    return this.prepareForFetch(this.leZip, queryKey, registerLEs);
  }
}

// TODO: do I need new here? using new in modules initializer
export const layoutElementsFetcher = new LayoutElementsFetcher();
