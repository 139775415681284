import $ from 'jquery';
import seedrandom from 'seedrandom';
import { fedopsLogger } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { LayoutElementsFetcher } from './layoutElementsFetcher';

import type { EditorInteractionName } from 'types/fedops';
import type { IModuleDependencies } from '@/presetApi';

// @ts-expect-error
Math.seedrandom = seedrandom;

const defaultGeolocation = {
  latitude: 37.77065,
  longitude: -122.387301,
};

const ADI_INTERACTION_NAMES_MAP: { [name: string]: EditorInteractionName } = {
  'load story': fedopsLogger.INTERACTIONS.ADI_PAAS.LOAD_STORY,
  'pre solution init': fedopsLogger.INTERACTIONS.ADI_PAAS.PRE_SOLUTION_INIT,
  'load layouts': fedopsLogger.INTERACTIONS.ADI_PAAS.LOAD_LAYOUTS,
  'init layout solver': fedopsLogger.INTERACTIONS.ADI_PAAS.INIT_LAYOUT_SOLVER,
  'init theme chooser': fedopsLogger.INTERACTIONS.ADI_PAAS.INIT_THEME_CHOOSER,
  setUpCacheByKit: fedopsLogger.INTERACTIONS.ADI_PAAS.SET_UP_CACHE_BY_KIT,
  'init rich text service':
    fedopsLogger.INTERACTIONS.ADI_PAAS.INIT_RICH_TEXT_SERVICE,
  footer: fedopsLogger.INTERACTIONS.ADI_PAAS.FOOTER,
  'initialize forms platform service':
    fedopsLogger.INTERACTIONS.ADI_PAAS.INITIALIZE_FORMS_PLATFORM_SERVICE,
  setStaticStoryStyles:
    fedopsLogger.INTERACTIONS.ADI_PAAS.SET_STATIC_STORY_STYLES,
  createFooterSolutions:
    fedopsLogger.INTERACTIONS.ADI_PAAS.CREATE_FOOTER_SOLUTIONS,
};

export class SantaModuleDependencies implements IModuleDependencies {
  public readonly BackendLayoutPreset: any = {};
  public metaDataBaseUrl = `${window.serviceTopology.scriptsLocationMap['site-generator-statics-metadata']}/`;
  private readonly _window: any = window;
  public layoutElementsFetcher = new LayoutElementsFetcher();
  public readonly $: JQueryStatic = $;
  private readonly location: any = location;
  public wizardCallbacksService: any = {};
  public BackendStory: any = {};
  public BackendTheme: any = {};

  BackendStoryLocale = {
    build: (storyData: any) => storyData,
  };

  getMonitoring() {
    return {
      mark: ({ name }: { name: string }) => {
        const interactionName = ADI_INTERACTION_NAMES_MAP[name];
        if (interactionName) {
          fedopsLogger.interactionStarted(interactionName);
        }
      },
      measure: ({ name }: { name: string }) => {
        const interactionName = ADI_INTERACTION_NAMES_MAP[name];
        if (interactionName) {
          fedopsLogger.interactionEnded(interactionName);
        }
      },
      warning: () => {},
      dataFixerWarning: () => {},
      error: (name: string, extra: any) => {
        ErrorReporter.captureException(new Error(name), {
          tags: {
            adi_preset_api_error: true,
          },
          extra,
        });
      },
    };
  }

  stories = {
    loadStoryLocales: () => Promise.resolve(),
  };

  getFlags() {
    return {
      classicEditorMode: true,
      fixButtonColorationContrast: true,
      fixStripWithOneColumnSignature: true,
      disablePaasRenderAnimate: true,
    };
  }

  async resolveGeoLocation(
    address: string,
  ): Promise<{ latitude: number; longitude: number }> {
    const maps = window.google?.maps;
    if (!maps) {
      return defaultGeolocation;
    }
    return new Promise((resolve) => {
      const geocoder = new maps.Geocoder();
      geocoder.geocode({ address }, (results: any, status: any) => {
        if (status === maps.GeocoderStatus.OK) {
          const locationObj = results[0].geometry.location;
          resolve({
            latitude: locationObj.lat(),
            longitude: locationObj.lng(),
          });
        } else {
          resolve(defaultGeolocation);
        }
      });
    });
  }

  getProGalleryToUse() {
    return this._window.proGalleryToUse;
  }

  setIsIgnoringBlurOnActiveEditor() {}

  async loadImage() {
    return;
  }

  window = {
    getADI: () => this._window.ADI,
    getMediaFrame: () => this._window.MediaFrame,
    getLocalStorage: () => this._window.localStorage,
    setRendererModel: (rendererModel: any) => {
      this._window.rendererModel = rendererModel;
    },
    setDocumentServices: (documentServices: any) => {
      this._window.documentServices = documentServices;
    },
    setSantaBase: (santaBase: any) => {
      this._window.santaBase = santaBase;
    },
    getComputedStyle: (obj: any): any => {
      return this._window.getComputedStyle(obj);
    },
    addEventListener: (event: string, listener: any) => {
      this._window.addEventListener(event, listener);
    },
    removeEventListener: (event: string, listener: any) => {
      this._window.removeEventListener(event, listener);
    },
    locationReload: () => {
      this._window.location.reload();
    },
    getWindow: () => {
      return this._window;
    },
  };

  document = {
    createElement: (e: string) => document.createElement(e),
    getHead: () => document.head || document.getElementsByTagName('head')[0],
    createTextNode: (e: string) => document.createTextNode(e),
    body: {
      appendChild: (x: Element) => document.body.appendChild(x),
      removeChild: (x: Element) => document.body.removeChild(x),
    },
  };

  angular = {
    element: (e: any) => this.$(e),
    translate: (text: string): string => {
      return text;
    },
    preferredLanguage: () => 'en',
    locationSearch: (search?: string, paramValue?: string) =>
      this.location.search(search, paramValue),
  };
}
